import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faSyncAlt, faUser, faCircleInfo, faRoute } from '@fortawesome/free-solid-svg-icons';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

// Función para cargar palabras desde los archivos
const cargarPalabras = async () => {
  try {
    const [response1, response2, response3] = await Promise.all([
      fetch('/palabras_todas.txt'),
      fetch('/palabras_todas_sin_conj.txt'),
      fetch('/palabras_mas_usadas.txt')
    ]);
    const [text1, text2, text3] = await Promise.all([response1.text(), response2.text(), response3.text()]);

    const palabrasOriginales = text2.split('\n').map((palabra) =>
      normalizarTexto(palabra.trim().toLowerCase())
    );
    const palabrasSinConjugacionesSet = new Set(
      text2.split('\n').map((palabra) => normalizarTexto(palabra.trim().toLowerCase()))
    );

    const palabrasMasUsadasSet = new Set(
      text3.split('\n').map((palabra) => palabra.trim().toLowerCase())
    );

    return { palabrasOriginales, palabrasSinConjugacionesSet, palabrasMasUsadasSet };
  } catch (error) {
    console.error('Error al cargar las palabras:', error);
    return { palabrasOriginales: [], palabrasSinConjugacionesSet: new Set(), palabrasMasUsadasSet: new Set() };
  }
};

// Función para normalizar texto eliminando tildes y diacríticos
const normalizarTexto = (texto) => texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

// Calcula la distancia en letras entre dos palabras
const calcularDistanciaLetras = (palabra1, palabra2) => {
  const normalizada1 = normalizarTexto(palabra1);
  const normalizada2 = normalizarTexto(palabra2);
  return [...normalizada1].reduce(
    (acc, letra, i) => acc + (letra !== normalizada2[i] ? 1 : 0),
    0
  );
};

// Función para encontrar una ruta exacta de pasos
const encontrarRutaExactaPasos = (palabras, palabraInicial, pasos, palabrasSinConjugacionesSet) => {
  const cola = [[palabraInicial]];
  const visitadas = new Set([normalizarTexto(palabraInicial)]);

  while (cola.length > 0) {
    const rutaActual = cola.shift();
    const palabraActual = rutaActual[rutaActual.length - 1];
    const palabraNormalizada = normalizarTexto(palabraActual);

    if (
      rutaActual.length === pasos + 1 &&
      palabrasSinConjugacionesSet.has(palabraNormalizada)
    ) {
      return rutaActual;
    }

    for (const nuevaPalabra of palabras) {
      const nuevaPalabraNormalizada = normalizarTexto(nuevaPalabra);
      if (
        calcularDistanciaLetras(palabraActual, nuevaPalabra) === 1 &&
        !visitadas.has(nuevaPalabraNormalizada)
      ) {
        visitadas.add(nuevaPalabraNormalizada);
        cola.push([...rutaActual, nuevaPalabra]);
      }
    }
  }

  return null;
};

// Función para generar la URL de definición en la RAE
const obtenerDefinicionRAE = (palabra) => `https://dle.rae.es/${palabra}`;

// Icono RAE
const IconoRAE = ({ palabra }) => (
  <a
    href={`https://dle.rae.es/${palabra}`}
    target="_blank"
    rel="noopener noreferrer"
    style={styles.iconoRAE}
  >
    <FontAwesomeIcon icon={faCircleInfo} />
  </a>
);

// Componente para mostrar palabras en el historial
const PalabraHistorial = ({ palabra, objetivo, esCorrecta }) => {
  if (!palabra) {
    return null;
  }

  return (
    <div style={styles.historialContainer}>
      <div style={styles.palabraWrapper}>
        <div style={styles.palabraContainer}>
          {[...palabra].map((letra, i) => {
            const letraNormalizada = normalizarTexto(letra);
            const objetivoNormalizada = normalizarTexto(objetivo[i]);

            return (
              <div
                key={i}
                style={{
                  ...styles.historialSquare,
                  backgroundColor: !esCorrecta
                    ? '#ffcccc' // Color rojo para incorrectas
                    : letraNormalizada === objetivoNormalizada
                    ? '#bada55' // Verde para correctas
                    : '#fff', // Blanco para no correctas
                }}
              >
                {letra}
              </div>
            );
          })}
        </div>
        {esCorrecta && <IconoRAE palabra={palabra} />}
      </div>
    </div>
  );
};

const PalabraObjetivo = ({ palabra, objetivo }) => (
  <div style={styles.historialContainer}>
    <div style={styles.palabraWrapper}>
      <div style={styles.palabraContainer}>
        {[...palabra].map((letra, i) => (
          <div
            key={i}
            style={{
              ...styles.historialSquare,
              backgroundColor: 'lightgray',
            }}
          >
            {letra}
          </div>
        ))}
      </div>
      <IconoRAE palabra={palabra} />
    </div>
  </div>
);

const ModalAnuncio = ({ visible, onClose }) => {
  if (!visible) return null;

  return (
    <div style={styles.anuncioOverlay}>
      <div style={styles.anuncioContent}>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8561175403706672"
          crossOrigin="anonymous"></script>
      <ins class="adsbygoogle"
          style={{display:'block'}}
          data-ad-client="ca-pub-8561175403706672"
          data-ad-slot="5876140681"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
        <button style={styles.anuncioCloseButton} onClick={onClose}>
          ✖
        </button>
      </div>
    </div>
  );
};


// Componente principal del juego
const CadenaDePalabras = () => {
  const [palabrasOriginales, setPalabrasOriginales] = useState([]);
  const [palabrasSinConjugacionesSet, setPalabrasSinConjugacionesSet] = useState(new Set());
  const [palabrasMasUsadasSet, setPalabrasMasUsadasSet] = useState(new Set());
  const [palabraInicial, setPalabraInicial] = useState('');
  const [palabraObjetivo, setPalabraObjetivo] = useState('');
  const [palabraActual, setPalabraActual] = useState('');
  const [palabraIngresada, setPalabraIngresada] = useState('');
  const [historial, setHistorial] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [pasos, setPasos] = useState(3);
  const [longitudPalabra, setLongitudPalabra] = useState(5);
  const [intentosRestantes, setIntentosRestantes] = useState(5);
  const [juegoTerminado, setJuegoTerminado] = useState(false);
  const [tecladoInput, setTecladoInput] = useState('');
  const [mostrarNormas, setMostrarNormas] = useState(true);
  const [mostrarAnuncio, setMostrarAnuncio] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [rutaInicial, setRutaInicial] = useState([]);

  const inputRef = useRef(null);
  const tecladoRef = useRef(null);

  useEffect(() => {
    if (!juegoTerminado && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [juegoTerminado, historial]);
  /*
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus(); // Asegura que el input esté enfocado al cargar el componente
    }
  }, [inputRef, palabraObjetivo, historial]);
*/
  // Componente para la entrada de palabras
  /*
  const InputPalabra = ({ longitud, palabra, onChange, inputRef }) => {
    return (
      <div style={styles.inputContainer}>
        {[...Array(longitud)].map((_, i) => (
          <div
            key={i}
            style={{
              ...styles.square,
              backgroundColor: inputError ? '#ffcccc' : 'transparent', // Fondo rojo dinámico
              animation: inputError ? 'shake 0.2s' : 'none', // Animación dinámica
            }}
          >
            {palabra[i] || ''}
          </div>
        ))}
        <input
          type="text"
          ref={inputRef}
          value={palabra}
          onChange={(e) => onChange(e.target.value)}
          maxLength={longitud}
          style={styles.overlayInput}
          inputMode="none"
        />
      </div>
    );
  };*/

  const InputPalabra = ({ longitud, palabra, onChange, inputRef }) => {
    useEffect(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus(); // Enfoca automáticamente el input
      }
      const theInput = document.querySelector('input');
      theInput.focus();
    }, [inputRef, palabra]);
  
    return (
      <div style={styles.inputContainer}>
        {[...Array(longitud)].map((_, i) => (
          <div
            key={i}
            style={{
              ...styles.square,
              backgroundColor: inputError ? '#ffcccc' : 'transparent',
              animation: inputError ? 'shake 0.2s' : 'none',
            }}
          >
            {palabra[i] || ''}
          </div>
        ))}
        {/* Campo de entrada nativo */}
        <input
          type="text"
          ref={inputRef}
          value={palabra}
          onChange={(e) => onChange(e.target.value)}
          maxLength={longitud}
          style={styles.overlayInputNative}
          inputMode="text"
        />
      </div>
    );
  };

  const Header = ({ onReload, onHelp }) => (
    <header style={styles.header}>
      <div style={styles.iconContainer}>
        <FontAwesomeIcon icon={faUser} style={styles.headerIcon} />
        <FontAwesomeIcon 
          icon={faRoute} // Icono de ruta
          style={styles.headerIcon} 
          onClick={() => {
            if (rutaInicial.length > 0) {
              alert(`${rutaInicial.join(' -> ')}`);
            }
          }} 
        />
      </div>
      <h1 style={styles.headerTitle}>WordJump</h1>
      <div style={styles.iconContainer}>
        <FontAwesomeIcon icon={faSyncAlt} style={styles.headerIcon} onClick={onReload} />
        <FontAwesomeIcon 
          icon={faQuestionCircle} 
          style={styles.headerIcon} 
          onClick={(e) => {
            e.stopPropagation(); // Evita que el evento de clic se propague
            setMostrarNormas(true); // Llama a la función del popup
          }}
        />
      </div>
    </header>
  );

  const reintentarJuego = () => {
    setIntentosRestantes(5); // Restablece los intentos
    setHistorial([palabraInicial]); // Vacía el historial manteniendo la palabra inicial
    setPalabraActual(palabraInicial); // Vuelve a la palabra inicial
    setMensaje(''); // Limpia cualquier mensaje previo
    setJuegoTerminado(false); // Reactiva el juego
  };

  const manejarCambioTeclado = (input) => {
    const palabraActualizada = input.slice(0, longitudPalabra); // Captura el valor actualizado
    setTecladoInput(input);
    setPalabraIngresada(palabraActualizada); // Actualiza el estado

    if (palabraActualizada.length === longitudPalabra) {
        setTimeout(() => {
            manejarEnvioPalabra(new Event("submit"), palabraActualizada); // Pasa el valor directamente
        }, 50); // Espera breve para asegurar que el input esté completo
    }
};

  const manejarTeclaEspecial = (button) => {
    if (button === "{bksp}") {
        const nuevoInput = tecladoInput.slice(0, -1); // Elimina el último carácter
        setTecladoInput(nuevoInput); // Actualiza el estado del teclado
        setPalabraIngresada(nuevoInput); // Actualiza el estado del input en React
        tecladoRef.current.setInput(nuevoInput); // Asegúrate de sincronizar el estado interno del teclado
    } else if (button === "{enter}") {
        manejarEnvioPalabra(new Event("submit"));
    }
};

  useEffect(() => {
    const inicializarJuego = async () => {
      const { palabrasOriginales, palabrasSinConjugacionesSet, palabrasMasUsadasSet } = await cargarPalabras();
      const palabrasMismaLongitud = palabrasOriginales.filter(
        (palabra) => normalizarTexto(palabra).length === longitudPalabra
      );
      setPalabrasOriginales(palabrasMismaLongitud);
      setPalabrasSinConjugacionesSet(palabrasSinConjugacionesSet);
      setPalabrasMasUsadasSet(palabrasMasUsadasSet);
    
      const palabrasSinConjugacionesArray = Array.from(palabrasSinConjugacionesSet).filter(
        (palabra) => palabra.length === longitudPalabra
      );
    
      const palabrasMasUsadasArray = Array.from(palabrasMasUsadasSet).filter(
        (palabra) => palabra.length === longitudPalabra
      );
    
      const palabraInicialAleatoria =
        palabrasMasUsadasArray[
          Math.floor(Math.random() * palabrasMasUsadasArray.length)
        ];
      setPalabraInicial(palabraInicialAleatoria);
      setPalabraActual(palabraInicialAleatoria);
      setHistorial([palabraInicialAleatoria]);
    
      const ruta = encontrarRutaExactaPasos(
        palabrasMismaLongitud,
        palabraInicialAleatoria,
        pasos,
        palabrasMasUsadasSet
      );
    
      if (ruta) {
        setRutaInicial(ruta); // Guarda la ruta inicial
        const palabraFinal = palabrasOriginales.find(
          (palabra) => normalizarTexto(palabra) === normalizarTexto(ruta[ruta.length - 1])
        );
        setPalabraObjetivo(palabraFinal);
      } else {
        inicializarJuego();
      }
      document.body.click();
    };
    inicializarJuego();
    
  }, [longitudPalabra]);
  /*
  useEffect(() => {
    if(palabraObjetivo.length) {
      inputRef?.current?.focus();
    }
  }, [palabraObjetivo])
  */
 /*
  useEffect(() => {
    inputRef?.current?.focus();
  }, [historial, juegoTerminado, inputRef])
*/
  /*
  const manejarCambioPalabra = (nuevaPalabra) => {
    setPalabraIngresada(normalizarTexto(nuevaPalabra.toLowerCase()));
  };*/

  const manejarCambioPalabra = (nuevaPalabra) => {
    const palabraNormalizada = normalizarTexto(nuevaPalabra.toLowerCase());
    setPalabraIngresada(palabraNormalizada);
  
    if (palabraNormalizada.length === longitudPalabra) {
      manejarEnvioPalabra(new Event("submit"), palabraNormalizada);
    }
  };

  const activarErrorInput = () => {
    const inputElement = inputRef.current;

    if (inputElement) {
        inputElement.classList.add('shake-error'); // Añade la clase de animación
        setTimeout(() => {
            inputElement.classList.remove('shake-error'); // Elimina la clase después de la animación
        }, 300); // Duración de la animación "shake"
    } else {
        console.error('No se encontró el input para aplicar la animación.');
    }

    // Cambiar fondo temporalmente
    setInputError(true);
    setTimeout(() => setInputError(false), 1000); // Restablece el fondo
  };

  const manejarEnvioPalabra = (e, palabra = palabraIngresada) => {
    e.preventDefault();

    if (juegoTerminado) return;

    const palabraNuevaNormalizada = normalizarTexto(palabra.toLowerCase());

    // Verifica si la palabra ingresada existe en palabras originales
    const palabraConTildes = palabrasOriginales.find(
        (palabraOriginal) => normalizarTexto(palabraOriginal) === palabraNuevaNormalizada
    );

    if (!palabraConTildes) {
        setMensaje('Palabra no válida.');
        activarErrorInput(); // Marcar como error
        ocultarMensajeDespuesDe3Segundos();
        return;
    }

    // Verifica que solo cambie una letra respecto a la palabra actual
    if (calcularDistanciaLetras(palabraActual, palabra) !== 1) {
        setMensaje('La palabra debe cambiar solo una letra.');
        activarErrorInput(); // Marcar como error
        ocultarMensajeDespuesDe3Segundos();
        return;
    }

    // Validación de conjugaciones
    const esCorrecta = palabrasSinConjugacionesSet.has(palabraNuevaNormalizada);

    if (!esCorrecta) {
        setMensaje('No se pueden utilizar conjugaciones.');
        activarErrorInput(); // Marcar como error
        ocultarMensajeDespuesDe3Segundos();
        return;
    }

    // Añadir palabra al historial
    setHistorial([
        ...historial,
        { palabra: palabraConTildes || palabra, esCorrecta },
    ]);

    setPalabraActual(palabraConTildes || palabra);
    setMensaje('¡Correcto! Sigue así.');
    setPalabraIngresada('');
    setTecladoInput('');
    tecladoRef.current?.setInput('');
    setIntentosRestantes((prev) => prev - 1);

    // Verifica si se completó el juego
    if (
        esCorrecta &&
        normalizarTexto(palabraConTildes || palabra) === normalizarTexto(palabraObjetivo)
    ) {
        setMensaje('¡Felicidades! Has completado este WordJump.');
        setJuegoTerminado(true);
    } else if (intentosRestantes - 1 === 0) {
        setMensaje('Se han agotado los intentos. ¡Prueba de nuevo!');
        setJuegoTerminado(true);
    }

    inputRef?.current?.focus();
};

useEffect(() => {
  const handleGlobalClick = (e) => {
    // Evita reenfocar si el clic es en un elemento específico
    if (e.target.closest('.no-focus')) return;

    e.preventDefault();
  
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  };

  // Añade el evento al documento
  document.addEventListener('mousedown', handleGlobalClick);

  // Limpia el evento al desmontar el componente
  return () => {
    document.removeEventListener('mousedown', handleGlobalClick);
  };
}, [inputRef]);
  
  // Función para ocultar el mensaje después de 3 segundos
  const ocultarMensajeDespuesDe3Segundos = () => {
    setTimeout(() => setMensaje(''), 2000);
  };

  const reiniciarJuego = async () => {
    setMostrarAnuncio(true); // Mostrar el anuncio
  
    // Configurar temporizador para cerrar automáticamente el anuncio
    setTimeout(() => {
      setMostrarAnuncio(false); // Cierra el anuncio después de 5 segundos
    }, 5000);
  
    const confirmar = window.confirm('Iniciar nueva partida');
    if (!confirmar) return; // Si el usuario cancela, no hace nada.
  
    try {
      const { palabrasOriginales, palabrasSinConjugacionesSet } = await cargarPalabras();
      const palabrasMismaLongitud = palabrasOriginales.filter(
        (palabra) => normalizarTexto(palabra).length === longitudPalabra
      );
      setPalabrasOriginales(palabrasMismaLongitud);
      setPalabrasSinConjugacionesSet(palabrasSinConjugacionesSet);
  
      const palabrasSinConjugacionesArray = Array.from(palabrasSinConjugacionesSet).filter(
        (palabra) => palabra.length === longitudPalabra
      );
  
      const palabraInicialAleatoria =
        palabrasSinConjugacionesArray[
          Math.floor(Math.random() * palabrasSinConjugacionesArray.length)
        ];
      setPalabraInicial(palabraInicialAleatoria);
      setPalabraActual(palabraInicialAleatoria);
      setHistorial([palabraInicialAleatoria]);
  
      const ruta = encontrarRutaExactaPasos(
        palabrasMismaLongitud,
        palabraInicialAleatoria,
        pasos,
        palabrasSinConjugacionesSet
      );
      if (ruta) {
        setRutaInicial(ruta);
        const palabraFinal = palabrasOriginales.find(
          (palabra) => normalizarTexto(palabra) === normalizarTexto(ruta[ruta.length - 1])
        );
        setPalabraObjetivo(palabraFinal);
      } else {
        reiniciarJuego();
      }
  
      setIntentosRestantes(5);
      setJuegoTerminado(false);
      setMensaje('');
      setPalabraIngresada('');

      // Forzar foco en el input después del reinicio
      inputRef?.current?.focus();
      document.body.click();
    } catch (error) {
      console.error("Error al reiniciar el juego:", error);
    }
  };

  const mostrarAyuda = () => {
    alert("Instrucciones del juego: Encuentra la palabra objetivo modificando una letra a la vez.");
  };

  const ModalNormas = ({ visible, onClose }) => {
    useEffect(() => {
      if (visible) {
        document.body.style.overflow = 'hidden'; // Deshabilita el scroll
      } else {
        document.body.style.overflow = ''; // Restaura el scroll
      }
      return () => {
        document.body.style.overflow = ''; // Limpia el estilo en caso de desmontar el componente
      };
    }, [visible]);
  
    if (!visible) return null;
  
    return (
      <div style={styles.modalOverlay}>
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalTitle}>WordJump</h2>
            <p style={styles.modalText}>
              ¡Bienvenido/a a WordJump! Tu objetivo es llegar desde una palabra inicial hasta una palabra final cambiando una letra en cada paso.
              Piensa rápido y elige sabiamente, ya que solo tienes 5 intentos para lograrlo.
            </p>
            <h3 style={styles.modalSubtitle}>Normas del Juego</h3>
            <p style={styles.modalText}>
              1. Solo puedes cambiar una letra por intento. Nada más, nada menos.
            </p>
            <p style={styles.modalText}>
              2. Cada intento debe ser una palabra real. Inventar palabras no está permitido.
            </p>
            <p style={styles.modalText}>
              3. Tienes un número limitado de intentos para alcanzar la palabra objetivo, 5 para ser exactos.
            </p>
            <p style={styles.modalText}>
              4. El objetivo es sencillo: llegar a la palabra final antes de quedarte sin intentos.
            </p>
            <p style={styles.modalText}>
              ¡Buena suerte y a jugar!
            </p>
          </div>
          <button onClick={onClose} style={styles.modalButton}>
            Entendido
          </button>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <Header onReload={reiniciarJuego} />
      {

        palabraObjetivo && <>          

          <form onSubmit={manejarEnvioPalabra} style={styles.form}>
            <div style={styles.palabraOriginal}>
              <PalabraHistorial key={'palabra-original'} palabra={palabraInicial} objetivo={palabraObjetivo} esCorrecta={true} />
            </div>
            {historial.map((palabra, index) => (
              <PalabraHistorial key={index} palabra={palabra.palabra} objetivo={palabraObjetivo} esCorrecta={palabra.esCorrecta} />
            ))}
            {
              !juegoTerminado && <>
                <InputPalabra
                  longitud={palabraInicial.length}
                  palabra={palabraIngresada}
                  onChange={manejarCambioPalabra}
                  inputRef={inputRef}
                />
              </>
            }
            {
              /*
              <button
                type="submit"
                style={{ ...styles.button, width: `${palabraInicial.length * 32}px` }}
              >
                Enviar
              </button>
              */
            }
            {
              !juegoTerminado && <>
                <div style={styles.historialList}>
                  <PalabraObjetivo key='palabra-objetivo' palabra={palabraObjetivo} objetivo={palabraObjetivo} />
                </div>
              </>
            }
          </form>
          
          {
            juegoTerminado && <>
              <div style={styles.buttonContainer}>
                {normalizarTexto(palabraActual) !== normalizarTexto(palabraObjetivo) && (
                  <button onClick={reintentarJuego} style={styles.retryButton}>
                    Reintentar
                  </button>
                )}
                  <button onClick={reiniciarJuego} style={styles.newGameButton}>
                    Nueva partida
                  </button>
              </div>
            </>
          }

          <p>{mensaje}</p>
          {/*
            <div style={styles.tecladoContainer}>
              <Keyboard
                keyboardRef={(r) => (tecladoRef.current = r)}
                onChange={manejarCambioTeclado}
                onKeyPress={manejarTeclaEspecial}
                input={tecladoInput} // Sincroniza el estado del teclado con React
                layout={{
                  default: [
                    "q w e r t y u i o p",
                    "a s d f g h j k l ñ",
                    "{as} z x c v b n m {bksp}",
                  ],
                }}
                display={{
                  "{as}": " ",
                  "{bksp}": "⌫",
                  "{enter}": "Intro",
                }}
                buttonTheme={[
                  {
                    class: "extra-wide-key left",
                    buttons: "{as}",
                  },
                  {
                    class: "extra-wide-key right",
                    buttons: "{bksp}",
                  },
                ]}
                theme="hg-theme-default hg-layout-default custom-keyboard"
              />
            </div>*/
          }
          <ModalNormas visible={mostrarNormas} onClose={() => setMostrarNormas(false)} />
          {/*<ModalAnuncio visible={mostrarAnuncio} onClose={() => setMostrarAnuncio(false)} />*/}
        </>
      }
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  square: {
    width: '40px',
    height: '40px',
    border: '1px solid black',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    margin: '0 2px',
  },
  overlayInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    cursor: 'text',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#000',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  historialSquare: {
    width: '40px',
    height: '40px',
    border: '1px solid black',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    margin: '0 2px',
  },
  historialList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
  },
  lengthInput: {
    padding: '5px',
    margin: '10px',
    width: '50px',
  },
  historialContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  palabraWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  palabraContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconoRAE: {
    position: 'absolute',
    top: '50%',
    right: '-25px', // Ajusta según el tamaño del ícono
    transform: 'translateY(-75%)',
    fontSize: '20px',
    textDecoration: 'none',
    color: '#555',
    cursor: 'pointer',
  },
  tecladoContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
    backgroundColor: '#f4f4f4',
    padding: 0,
    display: 'flex',
    justifyContent: 'center', // Centra el teclado
    alignItems: 'center', // Centra las filas verticalmente
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    backgroundColor: '#f4f4f4',
    padding: '0 10px',
    boxSizing: 'border-box',
    borderBottom: '1px solid #ccc',
  },
  headerTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'center',
    flex: 1,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '60px',
  },
  iconContainerLeft: {
    justifyContent: 'left'
  },
  headerIcon: {
    fontSize: '16px',
    cursor: 'pointer',
    color: '#555',
  },
  gameContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  retryButton: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#fff',
    color: '#000',
    border: '1px solid #000',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  newGameButton: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#000',
    color: 'white',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  palabraOriginal: {
    backgroundColor: 'lightgray',
    padding: '5px 3px',
    margin: '-5px 0',
    borderRadius: '7px'
  },
  modal: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    width: '90%', // Usamos el 90% del ancho disponible
    maxWidth: '400px', // Limita el ancho máximo en píxeles
    maxHeight: '80dvh', // Máximo 80% del alto visible
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    animation: 'fadeIn 0.3s ease-in-out',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Distribuye el contenido y el botón
    overflow: 'hidden', // Oculta cualquier contenido que se desborde
    padding: '30px'
  },
  modalContent: {
    flex: 1,
    overflowY: 'auto', // Habilita el scroll solo en esta sección
    textAlign: 'center', // Alinea el texto al principio
  },
  modalButton: {
    marginTop: '50px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#000',
    color: '#fff',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    alignSelf: 'center', // Centra el botón
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Oscurece el fondo con opacidad
    display: 'flex',
    justifyContent: 'center', // Centrar horizontalmente
    alignItems: 'center', // Centrar verticalmente
    zIndex: 1000,
    padding: '10px', // Espaciado para evitar cortes en pantallas pequeñas
    boxSizing: 'border-box', // Asegura que el padding se incluya en el ancho total
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#333',
  },
  modalText: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '10px',
    color: '#555',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  modalSubtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
    color: '#333',
  },
  anuncioOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo oscuro
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
  anuncioContent: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    width: '80%',
    maxWidth: '400px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
    position: 'relative',
  },
  anuncioTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  anuncioText: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '20px',
  },
  anuncioCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#333',
    fontSize: '16px',
    cursor: 'pointer',
  },
  '@keyframes shake': {
    '0%, 100%': { transform: 'translateX(0)' },
    '25%': { transform: 'translateX(-5px)' },
    '75%': { transform: 'translateX(5px)' },
  },
  overlayInputNative: {
    position: 'absolute',
    width: '1px', // Hazlo apenas visible
    height: '1px',
    opacity: 0.01, // Casi transparente
    zIndex: 1,
    cursor: 'text',
  },
};

export default CadenaDePalabras;