import logo from './logo.svg';
import './App.css';
import CadenaDePalabras from './CadenaDePalabras';

function App() {
  return (
    <>
      <CadenaDePalabras />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8561175403706672"
     crossorigin="anonymous"></script>
    </>
  );
}

export default App;
